import * as React from 'react';
import styles from './styles.module.scss';
import {Translation} from 'meditrip-common-web';
import {connect} from 'react-redux';
import {
    currentStepSelector,
    formSelector,
    wizardHeadStepIndexSelector
} from '../../../store/selectors/formSelectors';
import {RootState} from '../../../store/reducers';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {IFormValidatorService} from '../../../service/formValidatorService';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {InquiryFormStep} from '../inquiryFormStep';
import {IInquiryForm} from '../../../store/reducers/formSlice';
import {IStepManagerService} from '../../../service/stepManagerService';

interface IInquiryWizardProps extends RouteComponentProps {
    readonly currentStep: InquiryFormStep;
    readonly inquiryForm: IInquiryForm;
    readonly wizardHeadStepIndex: number;
}

class StepWizard extends React.Component<IInquiryWizardProps> {
    @lazyInject('FormValidatorService') private formValidator: IFormValidatorService;
    @lazyInject('StepManagerService') private stepManager: IStepManagerService;

    steps: any[] = [];

    constructor(props: IInquiryWizardProps) {
        super(props);
        fixInjectedProperties(this);
    }

    componentDidUpdate(prevProps: Readonly<IInquiryWizardProps>) {
        this.stepManager.manageWizardHeadNo();
    }

    render() {
        if (this.props.currentStep === InquiryFormStep.SUCCESS) {
            return null;
        }

        return (
            <React.Fragment>
                <nav className={styles.navigationMenu}>

                    { this.renderMobileStepLabel(this.props.currentStep) }

                    { this.renderWizardSteps() }

                </nav>
            </React.Fragment>
        );
    }

    private renderWizardSteps() {
        this.steps = this.stepManager.getWizardSteps();
        const wizardSteps: any[] = [];
        this.steps.forEach((step: any, i: number) => {
            const active = step.isActive ? styles.active : '';
            const enabled = step.isEnabled ? styles.enabled : '';
            const valid = step.isValid ? styles.valid : '';
            const head = step.isHead ? styles.head : '';
            wizardSteps.push(
                <li className={`${styles.wizardStep} ${active} ${enabled} ${valid} ${head}`} key={i}>
                    <span className={styles.line}>
                        <span className={styles.lineBtn} onClick={e => this.goToStep(e, step)}>
                            <span className={styles.number}>{ this.renderStepNumber(step.name) }</span>
                            <span className={styles.dot} />
                            <span className={styles.label}><Translation text={`form.consultationForm.${step.name}.title`}/></span>
                        </span>
                    </span>
                </li>
            )
        });

        return (<ul className={styles.wizardStepsContainer}>{ wizardSteps }</ul>);
    }

    private renderMobileStepLabel(step: InquiryFormStep) {
        return (
            <div className={styles.mobileStepIndicator}>
                { this.renderStepNumber(step)}
                <span className={styles.mobileCurrentStepName}>
                    <Translation text={`form.consultationForm.${this.props.currentStep}.title`}/>
                </span>
            </div>
        )
    };

    private renderStepNumber(stepName: InquiryFormStep) {
        const currentStepNo = this.stepManager.getStepIndex(stepName) + 1;
        return (
            <span className={styles.stepNumber}>
                <span className={styles.currentPage}>{currentStepNo}</span>/{this.steps.length}
            </span>
        )
    }

    private goToStep = (e: any, step: any) => {
        e.preventDefault();

        if (!step.isEnabled) {
            return;
        }

        this.stepManager.goTo(step.name);
    };
}

export default connect(
    (state: RootState) => ({
        currentStep: currentStepSelector(state),
        inquiryForm: formSelector(state),
        wizardHeadStepIndex: wizardHeadStepIndexSelector(state),
    }),
    {}
)(withRouter(StepWizard));
