import {FormControlType, IFormConfig, InputType, ValidationRules} from "meditrip-common-web";


export const loginFormConfig: typeof IFormConfig = {
    controlType: "form",
    class: "",
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: "auth_login",
            controlType: "group",
            class: "form-row",
            controls: {
                loginEmail: {
                    controlType: "control",
                    hostClass: "col-100",
                    defaultValue: "",
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.IS_EMAIL}
                    ],
                    placeholder: "loginForm.formControls.loginEmail.placeholder",
                    label: "loginForm.formControls.loginEmail.label",
                    type: InputType.EMAIL,
                }
            }
        },
        {
            key: "auth_password",
            controlType: "group",
            class: "form-row",
            controls: {
                loginPassword: {
                    controlType: "control",
                    hostClass: "col-100",
                    defaultValue: "",
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 4}}
                    ],
                    placeholder: "loginForm.formControls.loginPassword.placeholder",
                    label: "loginForm.formControls.loginPassword.label",
                    type: InputType.PASSWORD,
                }
            }
        }
    ]
};

export const registerFormConfig: typeof IFormConfig = {
    controlType: "form",
    class: "",
    dataAccessor: (data: any) => data, // data is not grouped
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: "group",
            key: "registration_email",
            class: "form-row",
            controls: {
                registrationEmail: {
                    controlType: "control",
                    hostClass: "col-100",
                    defaultValue: "",
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.IS_EMAIL}
                    ],
                    placeholder: "registerForm.formControls.registrationEmail.placeholder",
                    label: "registerForm.formControls.registrationEmail.label",
                    type: InputType.EMAIL,
                }
            }
        },
        {
            key: "registration_password",
            controlType: "group",
            class: "form-row",
            validationRules: [
                {
                    name: ValidationRules.ARE_FIELDS_SAME,
                    params: {accessors: [(value: any) => value.registrationPassword, (value: any) => value.registrationPasswordConfirm]}
                }
            ],

            controls: {
                registrationPassword: {
                    controlType: "control",
                    hostClass: "col-50",
                    defaultValue: "",
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 4}}
                    ],
                    placeholder: "registerForm.formControls.registrationPassword.placeholder",
                    label: "registerForm.formControls.registrationPassword.label",
                    type: InputType.PASSWORD,
                },
                registrationPasswordConfirm: {
                    controlType: "control",
                    hostClass: "col-50",
                    defaultValue: "",
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 4}}
                    ],
                    placeholder: "registerForm.formControls.registrationPasswordConfirm.placeholder",
                    label: "registerForm.formControls.registrationPasswordConfirm.label",
                    type: InputType.PASSWORD,
                }
            }
        },
        {
            key: "clauses",
            controlType: "group",
            class: "form-row",
            controls: {
                registrationTermsAgreement: {
                    controlType: "control",
                    hostClass: "col-100",
                    defaultValue: false,
                    formControlType: FormControlType.CHECKBOX,
                    validationRules: [
                        {name: ValidationRules.IS_TRUE},
                    ],
                    isLabelHidden: true,
                    isCheckboxLabelRaw: true,
                    checkboxLabel: `I agree to <a href="https://meditrip.me/privacy-policy/" target="_blank">privacy policy</a>, <a href="https://meditrip.me/personal-data-protection-policy/" target="_blank">personal data usage</a> and <a href="https://meditrip.me/personal-data-information-clause-art-13-gdpr/" target="_blank">art. 13 GDPR</a>*`,
                    outputDataMapper: (data: any) => {
                        if (data === 'true' || data === 'on') {
                            return true;
                        }
                        if (data === 'false') {
                            return false;
                        }

                        return data;
                    },
                }
            }
        }
    ]
};
