import createAPIHandler from "../base/apiFactory";
import {IAuthTokens} from "../../store/reducers/authSlice";

export const meditripAuthAPI = createAPIHandler({
    url: process.env.REACT_APP_AUTH_API_URL,
    defaultHeaders: {
        "Accept": "application/ld+json",
        "Content-type": "application/json"
    },
    mapper: (data: {
        token: string;
        refresh_token: string
    }): IAuthTokens => {
        return {
            authToken: data.token,
            refreshToken: data.refresh_token,
        };
    },
    isList: false
});
