import * as React from "react";
import {
    addAlert,
    authTokenSelector,
    calendarSelector,
    Form,
    FormControlChangeType,
    IFormConfig,
    Translation,
    getAvailableConsultationSlots,
    availableConsultationSlotsSelector,
    Loader,
    LoaderType,
    consultationDataLoadingSelector
} from "meditrip-common-web";
import {connect} from "react-redux";
import {changeCurrentStep, changeForm, IInquiryForm, changeInquiryId} from '../../../store/reducers/formSlice';
import {treatmentFormConfig} from "./treatmentFormConfig";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {InquiryFormStep} from '../inquiryFormStep';
import {IStepManagerService} from '../../../service/stepManagerService';
import {RootState} from "../../../store/reducers";
import {formSelector, inquiryIdSelector, isFormUpdatedSelector} from "../../../store/selectors/formSelectors";
import {changeEventPlaceholder, changeMetadataTimezone} from '../../../store/reducers/metadataSlice';
import {BehaviorSubject, of, Subscription} from "rxjs";
import {catchError, debounceTime, filter, tap} from "rxjs/operators";
import {IFormValidatorService} from '../../../service/formValidatorService';
import styles from "./styles.module.scss";
import {secretSelector} from "../../../store/selectors/authSelectors";
import {IFormConfigTranslationService} from "../../../service/formConfigTranslationService";
import {createEventPlaceholderAPI} from "../../../api/createEventPlaceholder";
import {metadataTimezoneSelector} from "../../../store/selectors/metadataSelectors";
import moment from "moment";

const uuid = require("uuid/v4");

interface IFormStepTreatmentProps {
    readonly authToken: string;
    readonly secret: string;
    readonly changeCurrentStep: typeof changeCurrentStep;
    readonly changeForm: typeof changeForm;
    readonly getAvailableConsultationSlots: typeof getAvailableConsultationSlots;
    readonly inquiryForm: IInquiryForm;
    readonly isInquiryFormUpdated: boolean;
    readonly inquiryId: string;
    readonly availableConsultationSlots: {[key: string]: any}[];
    readonly changeInquiryId: typeof changeInquiryId;
    readonly changeEventPlaceholder: typeof changeEventPlaceholder;
    readonly changeMetadataTimezone: typeof changeMetadataTimezone;
    readonly calendar: any;
    readonly timezone: string;
    readonly isLoading: boolean;
}

interface IFormStepTreatmentState {
    formConfig: typeof IFormConfig;
    value: any;
    isFormValid: boolean;
    selectedDate: Date | null;
    availableDates: Date[] | null;
    availableTimeSlots: {[key: string]: any}[] | null;
    currentMonth: number;
    timezone: string | null;
    calendarName: string | null;
    calendarMinutesAvailable: number | null;
}

class FormStepTreatment extends React.Component<IFormStepTreatmentProps, IFormStepTreatmentState> {
    @lazyInject('StepManagerService') private stepManager: IStepManagerService;
    @lazyInject('FormValidatorService') private formValidator: IFormValidatorService;
    @lazyInject('FormConfigTranslationService') private formConfigTranslator: IFormConfigTranslationService;

    readonly stepName: InquiryFormStep = InquiryFormStep.TREATMENT;
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    readonly subscriptions: Subscription[] = [];
    private isProcessing: boolean = false;

    constructor(props: any) {
        super(props);
        this.state = {
            formConfig: treatmentFormConfig,
            value: null,
            isFormValid: true,
            selectedDate: null,
            availableDates: null,
            availableTimeSlots: null,
            currentMonth: new Date().getMonth() + 1,
            timezone: null,
            calendarName: null,
            calendarMinutesAvailable: null
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        const translatedFormConfig = this.formConfigTranslator.setTranslationKeys(this.state.formConfig, this.stepName);
        this.setState({formConfig: translatedFormConfig});
        this.getTimezoneDetails();
        this.props.changeCurrentStep(this.stepName);
        if (this.props.inquiryForm) {
            this.updateFormFromState();
        }

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap(() => this.isProcessing = true),
                debounceTime(500),
                tap((data: any) => {
                    // if(data.value.consultationDate.name === 'month' || data.value.consultationDate.name === 'date') {
                    //     return;
                    // }
                    return this.onFormValueChange(data.value)
                }),
                tap(() => this.isProcessing = false),
            ).subscribe()
        );

        if(this.props.inquiryForm.consultationDate) {
            let config = treatmentFormConfig;
            config.controls[0].controls.consultationDate.selectedDate = new Date(this.props.inquiryForm.consultationDate);
            this.setState({
                formConfig: config,
                selectedDate: new Date(this.props.inquiryForm.consultationDate)
            });
        }

        if (this.props.inquiryForm.calendarId) {
            const date = this.props.inquiryForm.consultationDate ? new Date(this.props.inquiryForm.consultationDate) : new Date();
            this.setState({currentMonth: date.getMonth() + 1});
            this.getAvailableConsultationSlots(this.props.inquiryForm.calendarId, date);
        }

        if (this.props.calendar && this.props.calendar.name) {
            this.setState({
                calendarName: this.props.calendar.name,
                calendarMinutesAvailable: this.props.calendar?.account?.clinic?.currentMonthOnlineConsultationMinutesAvailable
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<IFormStepTreatmentProps>, prevState: Readonly<IFormStepTreatmentState>, snapshot?: any) {
        if (this.props.inquiryForm !== prevProps.inquiryForm) {
            this.updateFormFromState();
        }

        if (this.props.inquiryForm.calendarId !== prevProps.inquiryForm.calendarId && this.props.inquiryForm.calendarId) {
           const date = this.props.inquiryForm.consultationDate ? new Date(this.props.inquiryForm.consultationDate) : new Date();
           this.setState({currentMonth: date.getMonth() + 1});
           this.getAvailableConsultationSlots(this.props.inquiryForm.calendarId, date);
        }

        if (this.props.inquiryForm.consultationDate !== prevProps.inquiryForm.consultationDate
            && this.props.inquiryForm.consultationDate) {
          let config = treatmentFormConfig;
          config.controls[0].controls.consultationDate.selectedDate = new Date(this.props.inquiryForm.consultationDate);
          this.setState({
            formConfig: config,
            selectedDate: new Date(this.props.inquiryForm.consultationDate)
          });
        }

        if (this.props.availableConsultationSlots !== prevProps.availableConsultationSlots &&
            (this.props.calendar && !this.props.calendar.disabledBySystem)) {
            let availableDates: Date[] = [];
            let selectedYear = Object.keys(this.props.availableConsultationSlots)[0];
            const selectedMonths = (this.props.availableConsultationSlots as any)[selectedYear];
            Object.keys(selectedMonths).forEach((key: any) => {
                if (key === this.state.currentMonth.toString()) {
                    let selectedMonth = selectedMonths[key];
                    Object.keys(selectedMonth).forEach((key: any) => {
                        if (selectedMonth[key]) {
                            let selectedDay = selectedMonth[key];
                            let retrievedDays: any[] = [];
                            Object.keys(selectedDay).forEach((day: any) => retrievedDays.push(selectedDay[day]));
                            let isDayAvailable = retrievedDays.some((el: any) => el.is_free);
                            if (isDayAvailable) {
                                return availableDates.push(new Date(`${selectedYear}-${this.state.currentMonth}-${key}`));
                            }
                        }
                    });
                    return availableDates;
                }
            });
            let config = treatmentFormConfig;
            config.controls[0].controls.consultationDate.availableDates = availableDates;
            this.setState({
                availableDates: availableDates,
                formConfig: config
            });
        }

        if ((this.state.selectedDate !== prevState.selectedDate
            && this.state.selectedDate && this.props.availableConsultationSlots) ||
            (this.state.selectedDate && this.props.availableConsultationSlots !== prevProps.availableConsultationSlots)) {
            let year = new Date(this.state.selectedDate).getFullYear(),
                month = new Date(this.state.selectedDate).getMonth() + 1,
                day = new Date(this.state.selectedDate).getDate();

            if (year in this.props.availableConsultationSlots
                && month in this.props.availableConsultationSlots[year]
                && day in this.props.availableConsultationSlots[year][month]) {
                let time = this.props.availableConsultationSlots[year][month][day];
                let availableTimeSlots: { [key: string]: any }[] = [];

                if (time) {
                    Object.keys(time).forEach((key: any) => {
                        if (time[key].is_free) {
                            let timeSlot = time[key].starts_at;

                            if (this.state.selectedDate && (new Date(this.state.selectedDate).getDate() < new Date(timeSlot).getDate() ||
                                new Date(this.state.selectedDate).getDate() > new Date(timeSlot).getDate())) {
                                return;
                            }

                            availableTimeSlots.push({
                                value: new Date(time[key].starts_at).toISOString(),
                                displayValue: this.getTime(time[key].starts_at),
                                disabled: new Date(time[key].starts_at) < new Date()
                            })
                        }
                    });

                    let config = treatmentFormConfig;
                    config.controls[0].controls.consultationDate.availableTimeSlots = availableTimeSlots;
                    return this.setState({
                        availableTimeSlots: availableTimeSlots,
                        formConfig: config
                    });
                }
            }
        }

        if (this.props.calendar !== prevProps.calendar) {
            this.setState({
                calendarName: this.props.calendar.name,
                calendarMinutesAvailable: this.props.calendar?.account?.clinic?.currentMonthOnlineConsultationMinutesAvailable
            })
        }

        if (this.state.calendarMinutesAvailable !== prevState.calendarMinutesAvailable &&
            this.state.calendarMinutesAvailable &&
            (this.state.calendarMinutesAvailable <= 0 ||
            this.state.calendarMinutesAvailable < this.props.calendar.slotLength)) {
            let config = treatmentFormConfig;
            config.controls[0].controls.consultationDate.isDayDisabled = true;
            this.setState({
                formConfig: config
            });
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <React.Fragment>
                <header className={styles.headerContainer}>
                    <h2 className={'sr-only'}><Translation text={`form.consultationForm.treatment.title`}/></h2>
                    <h2 className={styles.calendarTitle}>{this.state.calendarName}</h2>
                </header>
                <div className={styles.formContainer}>
                    <Form config={this.state.formConfig}
                          controlName={'treatmentForm'}
                          onValueStateChange={this.onValueStateChange}
                          onValidationStateChange={this.onValidationStateChange}
                          value={this.state.value}/>
                    {this.renderFooter()}

                    <footer className="button-form-container single">
                        <button onClick={this.goNext}
                                className={`btn btn-next ${!this.state.isFormValid ? 'disabled' : ''}`}>
                            <Translation text={`form.buttons.next`}/></button>
                    </footer>
                    <Loader showLoader={this.props.isLoading} type={LoaderType.Local}/>
                </div>
            </React.Fragment>
        )
    }

  private renderFooter() {
    if (!this.state.availableTimeSlots) {
      return;
    }

    return (
      <div className={styles.footerContainer}>
        <div className={styles.timezoneContainer}>
          <span className={styles.icon} />
          <p className={styles.timezone}>Time zone: {this.state.timezone}</p>
        </div>
      </div>
    )
  }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
      Object.keys(value).forEach(key => {
          if (value[key] && value[key].hasOwnProperty("name") && value[key].name === 'month') {
              let currentMonth = value[key].value.getMonth() + 1;
              let config = treatmentFormConfig;
              config.controls[0].controls.consultationDate.availableTimeSlots = null;
              this.setState({
                currentMonth: currentMonth,
                availableTimeSlots: null
              });
              if (this.props.inquiryForm.calendarId) {
                  this.getAvailableConsultationSlots(this.props.inquiryForm.calendarId, value[key].value);
             }
          }

          if (value[key] && value[key].hasOwnProperty("name") && value[key].name === 'date') {
            return this.setState({
              selectedDate: new Date(value[key].value.setHours(0)),
              isFormValid: false
            });
          }

          if (value[key] && value[key].hasOwnProperty("name") && value[key].name === 'selectedDate') {
             this.setState({selectedDate: new Date(value.consultationDate.value)});
             this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
          }
      });
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };

    private goNext = () => {
        if (this.isProcessing) {
            return;
        }

        if (this.state.selectedDate && !this.props.inquiryId) {
            this.createEventPlaceholder(this.state.selectedDate);
        }

        if (this.props.inquiryId) {
            this.stepManager.goNext(this.stepName)
        }
    };

    // ========== CUSTOM METHODS ========== //

    private onFormValueChange = (value: any) => {
        let payload = value;
        if (this.state.selectedDate) {
          let date = new Date(this.state.selectedDate).toISOString();
          value['consultationDate'] = date;
        }

        this.props.changeForm(payload);
    };

    private getAvailableConsultationSlots(calendarId: string, date: Date) {
      const month = new Date().getMonth() + 1,
      firstDay = moment(new Date(date.getFullYear(), date.getMonth(), 1)).format('YYYY-MM-DD'),
      lastDay = moment(new Date(date.getFullYear(), date.getMonth() +1, 0)).format('YYYY-MM-DD');

      return this.props.getAvailableConsultationSlots(
        firstDay,
        lastDay,
        calendarId,
         // "clinic",
         // clinicId,
        month);
    }

    private createEventPlaceholder(value: Date) {
        let credentials: any = {};
        if (this.props.authToken) {
            credentials.authToken = this.props.authToken;
        }

        if (this.props.secret) {
            credentials.secret = this.props.secret
        }
        const validFrom = new Date().toISOString();
        const validUntil = new Date().setMinutes(new Date().getMinutes() + 10);
        const onlineConsultationId = uuid();
        let payload: any = {
            onlineConsultationId: onlineConsultationId
        };
        this.props.changeForm(payload);

        if (this.props.inquiryForm.calendarId) {
            return this.subscriptions.push(createEventPlaceholderAPI(
                credentials,
                validFrom,
                new Date(validUntil).toISOString(),
                value.toISOString(),
                null,
                'online_consultation',
                onlineConsultationId,
                this.props.inquiryForm.calendarId
                ).pipe(
                tap(() => {
                    this.isProcessing = true;
                    this.stepManager.goNext(this.stepName)
                }),
                catchError((error: any) => {
                    return of(
                        addAlert({message: error.response ?
                                error.response['hydra:description'] :
                                'Error occurred while reserving a time slot. Please choose another tile slot for your consultation'})
                    )
                })).subscribe()
            )
        }
    };

    private updateFormFromState = () => {
        const inquiry = this.props.inquiryForm;
        this.setState({
            isFormValid: this.formValidator.isStepValid(this.stepName),
            value: {
                consultationDate: inquiry.consultationDate
            }
        })
    };

    private getTime(date: string): string {
        let hours = new Date(date).getHours(),
            minutes = new Date(date).getMinutes(),
            time = `${hours}:${minutes === 0 ? '00' : minutes}`;
        return time;
    }

    private getTimezoneDetails() {
        let clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const today = new Date(),
            short = today.toLocaleDateString(undefined),
            full = today.toLocaleDateString(undefined, { timeZoneName: 'long' }),
            offset = today.getTimezoneOffset() / -60,
            sign = offset < 0 ? '-' : '+',
            gtm = `(GMT: ${sign}${offset})`;
        let time = today.toLocaleTimeString([], { hour: '2-digit', minute: "2-digit", hour12: false });

        const shortIndex = full.indexOf(short);
        this.props.changeMetadataTimezone(clientTimezone);
        if (shortIndex >= 0) {
            const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);
            return this.setState({
                timezone: `${trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '')} ${gtm}, ${time}`
            });

        } else {
            return this.setState({timezone: `${full} ${gtm}, ${time}`});
        }
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        secret: secretSelector(state),
        inquiryForm: formSelector(state),
        isInquiryFormUpdated: isFormUpdatedSelector(state),
        inquiryId: inquiryIdSelector(state),
        availableConsultationSlots: availableConsultationSlotsSelector(state),
        calendar: calendarSelector(state),
        timezone: metadataTimezoneSelector(state),
        isLoading: consultationDataLoadingSelector(state)
    }),
    {
        changeCurrentStep,
        changeForm,
        getAvailableConsultationSlots,
        changeInquiryId,
        changeEventPlaceholder,
        changeMetadataTimezone
    }
)(FormStepTreatment);
