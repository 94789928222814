import {meditripInquiryAPI} from "./provider/meditripInquiry";
import {Observable} from "rxjs";
import {RestQueryParams} from "meditrip-common-web";

export function getPaymentSecret(paymentId: string, accessToken: string): Observable<any> {
    return meditripInquiryAPI.get(
        `payment/${paymentId}/stripe/client-secret`,
        new RestQueryParams(),
        {
            accept: 'application/ld+json',
            'content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
    );
}
