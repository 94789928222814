import {Observable} from "rxjs";
import {RestQueryParams} from "meditrip-common-web";
import {meditripInquiryAPI} from "./provider/meditripInquiry";

export function getStripeAccountIdAPI(
    paymentId: string,
    clinicAccountId: string,
    authToken: string
): Observable<any> {
    return meditripInquiryAPI.post(
        `payments/${paymentId}/get_target_vendor_account_id`,
        {
            targetAccountId: clinicAccountId,
            paymentServiceName: "stripe"
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
