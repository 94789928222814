import {combineEpics, Epic, ofType, StateObservable} from "redux-observable";
import {catchError, mergeMap, switchMap, filter} from "rxjs/operators";
import {of} from "rxjs";
import {PayloadAction} from "redux-starter-kit";
import {addAlert, retrieveCalendar} from "meditrip-common-web";
import {changeFormInitialized,
    changeShowFormLoader,
    changeForm,
    changeFormSteps,
    changeIsClinicHost,
    IChangeIsClinicHost
} from "../reducers/formSlice";
import {getTreatmentsAPI} from "../../api/getTreatments";
import {changeIsAuthenticated, setSecret, setPaymentToken} from 'store/reducers/authSlice';
import {changeMetadataTimezone} from 'store/reducers/metadataSlice';
import {RootState} from '../reducers';
import {IChangeIsAuthenticated} from '../../../../admin-web/src/store/reducers/authSlice';
import {stepsAuthenticated, stepsNotAuthenticated} from '../../components/FormHost/formSteps';
import {stepsClinic} from '../../components/ClinicFormHost/formSteps';
import {InquiryFormStep} from "../../components/InquiryForm/inquiryFormStep";

const retrieveFormInitialDataEpic: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(changeIsClinicHost.type),
        switchMap((action: PayloadAction<IChangeIsClinicHost>): any => {
            return getTreatmentsAPI().pipe(
                mergeMap((resp: any) => {
                    const actions: any[] = [];
                    const urlParams = new URLSearchParams(state$.value.router.location.search),
                        cid = (urlParams).get("cid"),
                        calendarId = (urlParams).get("cal"),
                        startDate = (urlParams).get("startDate"),
                        // endDate = (urlParams).get("endDate"),
                        secret = (urlParams).get("s"),
                        token = (urlParams).get("token");

                    if (secret !== undefined) {
                        setSecret(secret);
                    }

                    const inquiryFormFields: any = {};

                    if (token) {
                        actions.push(setPaymentToken(token));
                    }

                    if (cid) {
                        inquiryFormFields['cid'] = cid;
                    }

                    if (calendarId) {
                        inquiryFormFields['calendarId'] = calendarId;
                        actions.push(retrieveCalendar(calendarId));
                    }

                    if (startDate) {
                        inquiryFormFields['consultationDate'] = startDate;
                    }

                    let steps = state$.value.auth.isAuthenticated ? stepsAuthenticated : stepsNotAuthenticated;

                    let inquiry = state$.value.form.inquiry;
                    if (token || state$.value.auth.paymentToken ||
                        (inquiry && inquiry.payments && Number(inquiry.payments[0].value.amount) === 0)) {
                        steps = steps.filter((step: string) => step !== InquiryFormStep.PAYMENT);
                    }

                    if (state$.value.form.isClinicHost) {
                        steps = stepsClinic;
                    }

                    actions.push(changeFormInitialized());
                    actions.push(changeShowFormLoader(false));
                    actions.push(changeForm(inquiryFormFields));
                    actions.push(changeFormSteps(Array.from(steps)));

                    let clientTimezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    actions.push(changeMetadataTimezone(clientTimezoneOffset));
                    return of (...actions);
                }),
                catchError((error: any) => {
                    return of(
                            changeShowFormLoader(false),
                            addAlert({message: error.response ? error.response['hydra:description'] : 'Error occurred'})
                        )
                    }
                ))
        })
    );

const inquiryWizardEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(changeIsAuthenticated.type),

        filter((e: any) => !state$.value.form.isClinicHost),
        switchMap((action: PayloadAction<IChangeIsAuthenticated>): any => {
            let steps = action.payload.isAuthenticated ? stepsAuthenticated : stepsNotAuthenticated;

            if (state$.value.auth.paymentToken) {
                steps = steps.filter((step: string) => step !== InquiryFormStep.PAYMENT);
            }
                return of(changeFormSteps(steps));
        }),
        catchError((error: any) => of(addAlert('Inquiry Wizard auth failed.')))
    );

const initFormEpic = combineEpics(
    retrieveFormInitialDataEpic,
    inquiryWizardEpic,
);

export default initFormEpic;
