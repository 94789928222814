import * as React from "react";
import styles from "./styles.module.scss";
import {connect} from "react-redux";
import {renewAuthToken, Toast} from 'meditrip-common-web';
import {changeFormSteps, changeIsClinicHost} from '../../store/reducers/formSlice';
import {authTokenSelector, refreshTokenSelector} from "../../store/selectors/authSelectors";
import {setAuthState} from "store/reducers/authSlice";
import jwtDecode from 'jwt-decode';
import {Redirect, Route, Switch} from 'react-router';
import {InquiryFormStep} from '../InquiryForm/inquiryFormStep';
import ClinicInquiryFormHost from "./ClinicInquiryFormHost";
import {stepsClinic} from './formSteps';
import {formStepsSelector} from '../../store/selectors/formSelectors';

interface IClinicFormHostProps {
    readonly authToken: string;
    readonly formSteps: InquiryFormStep[];
    readonly refreshToken: string;
    readonly renewAuthToken: typeof renewAuthToken;
    readonly changeFormSteps: typeof changeFormSteps;
    readonly setAuthState: typeof setAuthState;
    readonly changeIsClinicHost: typeof changeIsClinicHost;
}

interface IClinicFormHostState {
    refreshToken: string | null;
    count: number;
}

class ClinicFormHost extends React.Component<IClinicFormHostProps, IClinicFormHostState> {

    private authInterval: any;
    private count: number;
    private counterInterval: any;

    constructor(props: any) {
        super(props);

        this.state = {
            refreshToken: this.props.refreshToken,
            count: 0
        };
    }

    componentDidMount(): void {
        this.props.changeIsClinicHost(true);
        this.props.changeFormSteps(stepsClinic);

        if (this.props.authToken) {
            this.checkTokenExpiration();
            this.authInterval = setInterval(this.checkTokenExpiration, 1000 * 60 * 5);
        }
    }

    componentDidUpdate(
        prevProps: Readonly<any>,
        prevState: Readonly<any>,
        snapshot?: any
    ): void {
        if (this.props.refreshToken !== prevProps.refreshToken) {
            this.setState({refreshToken: this.props.refreshToken})
        }

        if (this.props.authToken && (this.props.authToken !== prevProps.authToken)) {
            const decoded: any = jwtDecode(this.props.authToken),
                tokenExpiration = decoded.exp,
                count = tokenExpiration - Math.floor(new Date().getTime() / 1000);
            this.setState({count: count});
        }
    }


    componentWillUnmount() {
        clearInterval(this.authInterval);
        clearInterval(this.counterInterval);
    }

    private renewSession = () => {
        this.props.renewAuthToken(this.state.refreshToken, this.props.authToken);
    };

    private checkTokenExpiration = (): void => {

        if (this.props.authToken) {
            const decoded: any = jwtDecode(this.props.authToken),
                tokenExpiration = decoded.exp,
                count = tokenExpiration - Math.floor(new Date().getTime() / 1000);

            this.setState({count: count});

            if (count < 600) {
                this.renewSession();
            }
        }
    };

    render() {
        if (!this.props.formSteps.length) {
            return null;
        }

        return (
            <section className={styles.clinicAxaHost}>
                <Switch>
                    <Route path={`/c/inquiry/:step/:inquiryId`} component={ClinicInquiryFormHost}/>
                    <Route path={`/c/inquiry/:step`} component={ClinicInquiryFormHost}/>
                    <Route component={() => (<Redirect to={`/c/inquiry/${InquiryFormStep.TREATMENT}`}/>)}/>
                </Switch>
                <Toast/>
            </section>
        );
    }
}

export default connect(
    (state: any) => ({
        authToken: authTokenSelector(state),
        refreshToken: refreshTokenSelector(state),
        formSteps: formStepsSelector(state),
    }),
    {
        renewAuthToken,
        setAuthState,
        changeFormSteps,
        changeIsClinicHost,
    }
)(ClinicFormHost);
