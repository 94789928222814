import * as React from 'react';
import {Translation, userAPI} from 'meditrip-common-web';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {
    isClinicHostSelector,
    currentStepSelector,
    formSelector,
    inquiryIdSelector,
    inquirySelector,
    isFormUpdatedSelector,
    showFormLoaderSelector,
    inquiryPaymentIdSelector
} from '../../../store/selectors/formSelectors';
import {
    changeCurrentStep,
    changeInquiry,
    changeInquiryId,
    changeIsInquiryFormUpdated,
    changeShowFormLoader,
    IInquiryForm,
    changeInquiryPaymentId,
    changeWizardHeadStepIndex,
} from '../../../store/reducers/formSlice';
import {setPaymentToken, setSecret} from '../../../store/reducers/authSlice';
import {authTokenSelector, paymentTokenSelector, secretSelector} from '../../../store/selectors/authSelectors';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {InquiryFormStep} from '../inquiryFormStep';
import SummaryList, {ISummaryTableItem} from './SummaryList';
import {QuoteTableConfigFactory} from './quoteTableConfigFactory';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {of, Subscription, throwError} from 'rxjs';
import {InquiryAPIPersister} from '../Utils/inquiryAPIPersister';
import {IStepManagerService} from '../../../service/stepManagerService';
import {IInquiryURLParams} from '../../../model/iInquiryURLParams';
import {RouteComponentProps} from 'react-router-dom';
import {IFormConfigTranslationService} from "../../../service/formConfigTranslationService";
import {confirmPayment} from "../../../api/confirmPayment";
import {IOnlineConsultationParticipant} from "../../../api/changeOnlineConsultation";
import {metadataTimezoneSelector} from "../../../store/selectors/metadataSelectors";

interface IFormStepSummaryProps extends RouteComponentProps {
    readonly currentStep: InquiryFormStep;
    readonly inquiryForm: IInquiryForm;
    readonly inquiryId: string | null;
    readonly inquiryPaymentId: string | null;
    readonly authToken: string;
    readonly paymentToken: string | null;
    readonly secret: string;
    readonly showFormLoader: boolean,
    readonly changeShowFormLoader: typeof changeShowFormLoader;
    readonly changeCurrentStep: typeof changeCurrentStep;
    readonly inquiry: any;
    readonly isClinicHost: boolean;
    readonly isInquiryFormUpdated: boolean;
    readonly changeIsInquiryFormUpdated: typeof changeIsInquiryFormUpdated;
    readonly changeInquiryId: typeof changeInquiryId;
    readonly changeInquiryPaymentId: typeof changeInquiryPaymentId;
    readonly changeInquiry: typeof changeInquiry;
    readonly setSecret: typeof setSecret;
    readonly setPaymentToken: typeof setPaymentToken;
    readonly changeWizardHeadStepIndex: typeof changeWizardHeadStepIndex;
    readonly timezone: string | null;
}

interface IFormStepSummaryState {
    summaryTableConfig: ISummaryTableItem[];
}

class FormStepSummary extends React.Component<IFormStepSummaryProps, IFormStepSummaryState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    @lazyInject('StepManagerService') private stepManager: IStepManagerService;
    @lazyInject('FormConfigTranslationService') private formConfigTranslator: IFormConfigTranslationService;

    readonly stepName: InquiryFormStep = InquiryFormStep.SUMMARY;
    readonly subscriptions: Subscription[] = [];

    constructor(props: any) {
        super(props);
        this.state = {
            summaryTableConfig: [],
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.props.changeCurrentStep(this.stepName);
        this.props.changeShowFormLoader(true);

        const {inquiryId} = this.props.match.params as IInquiryURLParams;
        if (inquiryId && !this.props.isInquiryFormUpdated) {
            this.subscriptions.push(this.fetchInquiry(inquiryId).subscribe())
        } else {
            this.subscriptions.push(
                this.persistInquiry(inquiryId, (null === inquiryId || undefined === inquiryId)).subscribe()
            );
            if (this.needsToClearSecret(inquiryId)) {
                this.props.setSecret(null);
            }
        }
    }

    componentWillUnmount() {
        this.props.changeShowFormLoader(false);
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <React.Fragment>
                <header>
                    <h2 className={'sr-only'}><Translation text={`form.consultationForm.summary.title`}/></h2>
                </header>
                <SummaryList items={this.state.summaryTableConfig}/>
                <footer className="button-form-container">
                    <button onClick={this.goPrev}
                            className="btn btn-prev">
                        <Translation text={`form.buttons.backToEdit`}/>
                    </button>

                    { this.renderNextButton() }

                </footer>
            </React.Fragment>
        );
    }

    private goNext = () => {
        if (this.props.paymentToken && this.props.inquiryPaymentId) {
            return confirmPayment(
                this.props.inquiryPaymentId,
                this.props.authToken,
                this.props.paymentToken
            ).pipe(
                tap(() => {
                    this.alertManager.handlePaymentSuccess();
                    this.props.changeShowFormLoader(false);
                    this.props.setPaymentToken(null);
                    this.stepManager.goNext(this.stepName);
                }),
                catchError((error: any) => {
                    return of(this.alertManager.handleFormApiError(error));
                }),
            ).subscribe();
        }

        if (!this.props.paymentToken) {
            this.props.changeIsInquiryFormUpdated(false);
            this.stepManager.goNext(this.stepName)
        }
    };

    private goPrev = () => {
        this.stepManager.goPrev(this.stepName)
    };

    // ========== CUSTOM METHODS ========== //

    private renderNextButton() {
        if (this.props.isClinicHost) {
            return null;
        }

        return (
            <button
                className="btn btn-next"
                tabIndex={0}
                onClick={this.goNext}>
                <Translation text={`form.buttons.next`}/>
            </button>
        )
    }

    private updateQuoteFromAPI = (apiData: any) => {
        const summaryTableConfig = (new QuoteTableConfigFactory()).create(this.props.inquiryForm, apiData);
        const translatedSummaryTableConfig = this.formConfigTranslator.setSummaryListTranslationKeys(summaryTableConfig, this.stepName);
        this.setState({
           summaryTableConfig: translatedSummaryTableConfig,
        });
    };

    private persistInquiry(inquiryId: string | null, isCreate: boolean) {
        return userAPI(this.props.authToken).pipe(
            tap((resp: any) => {
                let onlineParticipants: IOnlineConsultationParticipant = {
                    accountId: resp.account.id,
                    timeZone: this.props.timezone
                };

                return this.persister.persist(
                    inquiryId,
                    this.props.inquiryForm,
                    this.credentials,
                    this.props.inquiry,
                    onlineParticipants,
                    this.props.paymentToken
                ).pipe(
                    tap(retrievedInquiryId => {
                        if (isCreate) {
                            this.props.changeInquiryId(retrievedInquiryId);
                            const secretParam: string = this.props.isClinicHost ? `?s=${this.props.secret}` : '';
                            const url = `/inquiry/summary/${retrievedInquiryId}${secretParam}`;
                            window.parent.postMessage(url, '*');
                            window.history.replaceState(null, '', url);
                        }
                    }),
                    catchError((error) => {
                        this.alertManager.handleFormApiError(error.response);
                        if (isCreate) {
                            this.stepManager.goTo(InquiryFormStep.PATIENT);
                            this.props.changeWizardHeadStepIndex(1);
                        }
                        return throwError(error);
                    }),
                    tap(() => {
                        this.props.changeIsInquiryFormUpdated(false)
                    }),
                    switchMap(retrievedInquiryId => this.fetchInquiry(retrievedInquiryId))
                ).subscribe();
            })
        );
    }

    private fetchInquiry(inquiryId: string) {
        return this.persister.fetch(inquiryId, this.credentials).pipe(
            tap(data => {
                this.updateQuoteFromAPI(data);
                this.props.changeInquiry(data);
                this.props.changeInquiryPaymentId(data.payments[0].id);
                const clinicLocationUrl: string = this.props.isClinicHost ? `/inquiry/summary/${inquiryId}?s=${this.props.secret}` : `/inquiry/summary/${inquiryId}`;
                window.parent.postMessage(clinicLocationUrl, '*');

            }),
            catchError(error => {
                this.alertManager.addAlert(`${error.response} You are not allowed to view this Inquiry, contact your Clinic administrator`);
                this.alertManager.handleFormApiError(error);
                this.stepManager.goTo(InquiryFormStep.PATIENT);
                return throwError(error);
            }),
            tap(() => {
                this.props.changeIsInquiryFormUpdated(false);
                this.props.changeShowFormLoader(false);
                return of();
            }),
        );
    };

    private needsToClearSecret(inquiryId: string | null): boolean {
        return !this.props.isClinicHost && inquiryId === null && null !== this.props.authToken && undefined !== this.props.authToken;
    }

    private get persister() {
        return new InquiryAPIPersister();
    }

    private get credentials() {
        if (this.props.authToken) {
            return {authToken: this.props.authToken};
        } else if (this.props.secret) {
            return {secret: this.props.secret};
        }
        throw new Error(`Cannot establish correct credentials to persist Inquiry.`);
    }
}

export default connect(
    (state: RootState) => ({
        currentStep: currentStepSelector(state),
        authToken: authTokenSelector(state),
        timezone: metadataTimezoneSelector(state),
        paymentToken: paymentTokenSelector(state),
        inquiryForm: formSelector(state),
        inquiryId: inquiryIdSelector(state),
        inquiryPaymentId: inquiryPaymentIdSelector(state),
        isClinicHost: isClinicHostSelector(state),
        secret: secretSelector(state),
        inquiry: inquirySelector(state),
        isInquiryFormUpdated: isFormUpdatedSelector(state),
        showFormLoader: showFormLoaderSelector(state),
    }),
    {
        changeCurrentStep,
        changeShowFormLoader,
        changeIsInquiryFormUpdated,
        changeInquiryId,
        changeInquiryPaymentId,
        changeInquiry,
        setSecret,
        setPaymentToken,
        changeWizardHeadStepIndex
    }
)(FormStepSummary);
