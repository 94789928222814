import {Observable} from "rxjs";
import {meditripRegistrationAPI} from "./provider/meditripRegistration";
import {RestQueryParams} from "meditrip-common-web";

export function registerPatientAPI(
    login: string,
    plainPassword: string,
): Observable<any> {
    return meditripRegistrationAPI.post(
        "register-patient",
        {
            login: login,
            plainPassword: plainPassword,
        },
        new RestQueryParams()
    );
}
