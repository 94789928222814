import * as React from "react";
import {Form, FormControlChangeType, IFormConfig, Translation} from 'meditrip-common-web';
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {formSelector,} from "../../../store/selectors/formSelectors";
import {
    changeCurrentStep,
    changeForm,
    changeShowFormLoader,
    IInquiryForm
} from "../../../store/reducers/formSlice";
import {loginFormConfig} from "../authFormConfigs";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {InquiryFormStep} from '../../InquiryForm/inquiryFormStep';
import {IStepManagerService} from '../../../service/stepManagerService';
import {IAuthenticationFlowService} from "../../../service/authenticationFlowService";
import {BehaviorSubject, Subscription, throwError} from "rxjs";
import {catchError, debounceTime, filter, tap} from "rxjs/operators";

interface ILoginComponentProps {
    readonly inquiryForm: IInquiryForm;
    readonly changeShowFormLoader: typeof changeShowFormLoader;
    readonly changeForm: typeof changeForm;
    readonly changeCurrentStep: typeof changeCurrentStep;
}

interface ILoginComponentState {
    formConfig: typeof IFormConfig;
    value: any;
    isFormValid: boolean;
}

class LoginComponent extends React.Component<ILoginComponentProps, ILoginComponentState> {
    @lazyInject('StepManagerService') private stepManager: IStepManagerService;
    @lazyInject('AuthenticationFlowService') private authenticationFlow: IAuthenticationFlowService;

    readonly stepName: InquiryFormStep = InquiryFormStep.ACCOUNT;
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    readonly subscriptions: Subscription[] = [];
    private isProcessing: boolean = false;

    constructor(props: any) {
        super(props);
        this.state = {
            formConfig: loginFormConfig,
            value: null,
            isFormValid: false,
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap(() => this.isProcessing = true),
                debounceTime(500),
                tap((data: any) => this.onFormValueChange(data.value)),
                tap(() => this.isProcessing = false),
            ).subscribe()
        );
    }

    componentWillUnmount() {
        this.props.changeShowFormLoader(false);
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <React.Fragment>
                <Form config={this.state.formConfig}
                      onValueStateChange={this.onValueStateChange}
                      onValidationStateChange={this.onValidationStateChange}
                      controlName={"loginForm"}
                      value={this.state.value}/>
                <footer className="button-form-container">
                    <button onClick={this.goPrev}
                            className="btn btn-prev">
                        <Translation text={`form.buttons.backToEdit`}/>
                    </button>
                    <button className={`btn btn-next ${!this.state.isFormValid ? 'disabled' : ''}`}
                            tabIndex={!this.state.isFormValid ? -1 : 0}
                            disabled={!this.state.isFormValid}
                            onClick={this.login}>
                        <Translation text={`form.buttons.next`}/>
                    </button>
                </footer>
            </React.Fragment>
        )
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.isProcessing = true;
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };

    private goPrev = () => {
        if (this.isProcessing) {
            return;
        }
        this.isProcessing = true;
        this.stepManager.goPrev(this.stepName)
    };

    // ========== CUSTOM METHODS ========== //

    private onFormValueChange = (value: any) => {
        this.props.changeForm(value);
    };

    private login = (e: any) => {
        e.preventDefault();

        const loginEmail = this.props.inquiryForm.loginEmail,
            loginPassword = this.props.inquiryForm.loginPassword;

        if (this.isProcessing || !this.state.isFormValid || !loginEmail || !loginPassword) {
            return;
        }

        this.isProcessing = true;
        this.subscriptions.push(
            this.authenticationFlow.submitLogin(loginEmail, loginPassword).pipe(
                catchError((error) => {
                    this.isProcessing = false;
                    return throwError(error);
                }),
            ).subscribe()
        );
    }
}

export default connect(
    (state: RootState) => ({
        inquiryForm: formSelector(state),
    }),
    {
        changeShowFormLoader,
        changeForm,
        changeCurrentStep,
    }
)(LoginComponent);

