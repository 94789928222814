import {Observable} from "rxjs";
import {meditripRegistrationAPI} from "./provider/meditripRegistration";
import {RestQueryParams} from "meditrip-common-web";

export function resetPasswordAPI(
    email: string,
    resetPasswordComponentUrl: string
): Observable<any> {
    return meditripRegistrationAPI.post(
        "reset-password",
        {
            email: email,
            resetPasswordComponentUrl: resetPasswordComponentUrl
        },
        new RestQueryParams()
    );
}
