import {combineEpics} from 'redux-observable';
import {renewAuthTokenEpic, reauthenticateEpic, sharedLoginEpic, calendarEpic} from 'meditrip-common-web';
import authEpic from './auth';
import initFormEpic from "./initForm";
import calendarEventsEpic from "./calendarEvents";

export const rootEpic = combineEpics(
    initFormEpic,
    authEpic,
    sharedLoginEpic,
    renewAuthTokenEpic,
    reauthenticateEpic,
    calendarEventsEpic,
    calendarEpic
);
