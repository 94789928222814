import * as React from "react";
import styles from "./styles.module.scss";
import {connect} from "react-redux";
import {
    formStepsSelector,
    isClinicHostSelector,
    formSelector,
    inquiryIdSelector,
    showFormLoaderSelector, isFormInitializedSelector
} from '../../store/selectors/formSelectors';
import {RootState} from "../../store/reducers";
import {Route, RouteComponentProps, Switch, withRouter} from 'react-router-dom';
import FormStepTreatment from "./FormStepTreatment";
import FormStepPatient from './FormStepPatient';
import FormStepSummary from './FormStepSummary';
import FormStepAccount from "./FormStepAccount";
import FormStepPayment from "./FormStepPayment";
import StepWizard from './StepWizard';
import {changeCalendar, Loader} from 'meditrip-common-web';
import {InquiryFormStep} from './inquiryFormStep';
import FormStepSuccess from "./FormStepSuccess";
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {IFormValidatorService} from '../../service/formValidatorService';
import {changeInquiry, changeInquiryId, changeShowFormLoader, IInquiryForm, changeInquiryPaymentId} from '../../store/reducers/formSlice';
import {authTokenSelector, secretSelector} from '../../store/selectors/authSelectors';
import {setSecret} from "store/reducers/authSlice";
import {IInquiryFormUpdateService} from "../../service/inquiryFormUpdateService";
import {IAlertManagerService} from "../../service/alertManagerService";
import {assignInquiryToUserAPI} from "../../api/assignInquiryToUserAPI";
import {IStepManagerService} from '../../service/stepManagerService';
import {Observable, of, Subscription, throwError} from "rxjs";
import {catchError, tap, switchMap} from "rxjs/operators";
import {InquiryAPIPersister} from "./Utils/inquiryAPIPersister";
import {IInquiryURLParams} from '../../model/iInquiryURLParams';

const uuid = require("uuid/v4");

interface IInquiryFormProps extends RouteComponentProps {
    readonly authToken: string | null;
    readonly secret: string | null;
    readonly formSteps: InquiryFormStep[];
    readonly inquiryId: string | null;
    readonly isClinicHost: boolean;
    readonly showFormLoader: boolean;
    readonly inquiryForm: IInquiryForm;
    readonly isFormInitialized: boolean;

    readonly changeInquiryId: typeof changeInquiryId;
    readonly changeInquiry: typeof changeInquiry;
    readonly changeShowFormLoader: typeof changeShowFormLoader;
    readonly setSecret: typeof setSecret;
    readonly changeCalendar: typeof changeCalendar;
    readonly changeInquiryPaymentId: typeof changeInquiryPaymentId;
}

interface IInquiryFormState {
    isInquiryLoading: boolean;
}

class InquiryForm extends React.Component<IInquiryFormProps, IInquiryFormState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    @lazyInject('InquiryFormUpdateService') private inquiryFormUpdater: IInquiryFormUpdateService;
    @lazyInject('FormValidatorService') private formValidator: IFormValidatorService;
    @lazyInject('StepManagerService') private stepManager: IStepManagerService;

    private subscription: Subscription | null = null;

    constructor(props: any) {
        super(props);

        this.state = {
            isInquiryLoading: false,
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.props.changeShowFormLoader(true);

        const {inquiryId} = this.props.match.params as IInquiryURLParams;
        if (inquiryId) {
            this.props.changeInquiryId(inquiryId);
            let o: Observable<any> = new Observable<any>();
            if (this.props.secret && this.props.authToken && inquiryId) {
                o = assignInquiryToUserAPI(inquiryId, this.props.authToken, this.props.secret).pipe(
                    tap(() => {
                        if (this.needsToClearSecret(inquiryId)) {
                            this.props.setSecret(null);
                        }
                    }),
                    catchError((error) => {
                        if (this.needsToClearSecret(inquiryId)) {
                            this.props.setSecret(null);
                        }
                        this.props.changeShowFormLoader(false);
                        return throwError(error);
                    }),
                    switchMap(() => this.fetchInquiry(inquiryId)),
                )
            } else {
                o = this.fetchInquiry(inquiryId)
            }
            this.subscription = o.subscribe();

        } else {
            if (!this.props.secret || this.props.secret === '') {
                const uuidSecret: any = uuid();
                this.props.setSecret(uuidSecret);
            }
        }
    }

    componentWillUnmount() {
        if (null !== this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    componentDidUpdate(prevProps: Readonly<IInquiryFormProps>, prevState: Readonly<IInquiryFormState>, snapshot?: any) {
        if (!this.props.showFormLoader) {
          this.stepGuardManager();
        }
    }

    render() {
        return (
            <React.Fragment>
                <div // toDO wymyślić jak się pozbyć klasy iframeStyling
                    className={`${styles.insuranceInquiryFormWrapper} ${this.props.isClinicHost ? styles.iframeStyling : ''}`}>
                    <div className={styles.insuranceInquiryFormContainer}>

                        <StepWizard />
                        <div className={styles.routingContainer} id="formContainer">
                            {this.renderStep()}
                        </div>
                    </div>
                    {this.renderLoader()}
                </div>
            </React.Fragment>
        );
    }

    private renderStep() {
        if (!this.props.isFormInitialized || this.state.isInquiryLoading) {
            return null;
        }

        return (<Switch>
                <Route exact path={`/inquiry/${InquiryFormStep.TREATMENT}`}
                       component={FormStepTreatment}/>
                <Route path={`/c/inquiry/${InquiryFormStep.TREATMENT}`}
                       component={FormStepTreatment}/>
                <Route path={`/inquiry/${InquiryFormStep.TREATMENT}/:inquiryId`}
                       component={FormStepTreatment}/>

                <Route exact path={`/inquiry/${InquiryFormStep.PATIENT}`} component={FormStepPatient}/>
                <Route path={`/c/inquiry/${InquiryFormStep.PATIENT}`} component={FormStepPatient}/>
                <Route path={`/inquiry/${InquiryFormStep.PATIENT}/:inquiryId`}
                       component={FormStepPatient}/>

                <Route exact path={`/inquiry/${InquiryFormStep.SUMMARY}`} component={FormStepSummary}/>
                <Route path={`/inquiry/${InquiryFormStep.SUMMARY}/:inquiryId`}
                       component={FormStepSummary}/>
                <Route path={`/c/inquiry/${InquiryFormStep.SUMMARY}/:inquiryId`} component={FormStepSummary}/>
                <Route path={`/c/inquiry/${InquiryFormStep.SUMMARY}`} component={FormStepSummary}/>

                <Route exact path={`/inquiry/${InquiryFormStep.ACCOUNT}`} component={FormStepAccount}/>
                <Route path={`/c/inquiry/${InquiryFormStep.ACCOUNT}`} component={FormStepAccount}/>
                <Route path={`/inquiry/${InquiryFormStep.ACCOUNT}/:inquiryId`}
                       component={FormStepAccount}/>

                <Route exact path={`/inquiry/${InquiryFormStep.PAYMENT}`} component={FormStepPayment}/>
                <Route exact path={`/c/inquiry/${InquiryFormStep.PAYMENT}`} component={FormStepPayment}/>
                <Route path={`/inquiry/${InquiryFormStep.PAYMENT}/:inquiryId`}
                       component={FormStepPayment}/>

                <Route exact path={`/inquiry/${InquiryFormStep.SUCCESS}`} component={FormStepSuccess}/>
                <Route path={`/c/inquiry/${InquiryFormStep.SUCCESS}`} component={FormStepSuccess}/>
                <Route path={`/inquiry/${InquiryFormStep.SUCCESS}/:inquiryId`} component={FormStepSuccess}/>
            </Switch>
        );
    }

    private renderLoader() {
        if (!this.props.showFormLoader) {
            return;
        }
        return (<Loader showLocalLoader={this.props.showFormLoader}/>);
    }

    private stepGuardManager() {
        if (this.state.isInquiryLoading) {
            return;
        }
        const {step, inquiryId} = this.props.match.params as IInquiryURLParams;
        const stepOrder = this.props.formSteps;

        let currentStepNo: any = null;
        for (let i = 0; i < stepOrder.length; i++) {
            if (stepOrder[i] === step) {
                currentStepNo = i;
                break;
            }
        }

        if (currentStepNo === null) {
            this.props.history.push(`/inquiry/${InquiryFormStep.TREATMENT}/${inquiryId}`);
        }


        let stepToEnter: any = InquiryFormStep.TREATMENT;
        for (let i = 0; i < stepOrder.length; i++) {
            const isValid = this.formValidator.isStepValid(stepOrder[i]);

            if (!isValid) {
                stepToEnter = stepOrder[i];
                break;
            }

            if (currentStepNo === i) {
                stepToEnter = null;
                break;
            }
        }

        if (stepToEnter && stepToEnter !== stepOrder[currentStepNo]) {
            const path = [''];

            if (this.props.isClinicHost) {
                path.push('c');
            }

            path.push('inquiry');

            const pathStep = (stepToEnter === InquiryFormStep.SUMMARY && !this.props.inquiryId) ? InquiryFormStep.PATIENT : stepToEnter;
            path.push(pathStep);

            if (this.props.inquiryId) {
                path.push(this.props.inquiryId);
            }

            this.props.history.push(path.join('/'));
        }
    }

    // TODO:  when entering url from clinic in incognito mode, there is a bug. secret is not loading that fast as this getter. With second try is loading normally.
    private get credentials() {
        if (this.props.authToken) {
            return {authToken: this.props.authToken};
        } else if (this.props.secret) {
            return {secret: this.props.secret};
        }

        return {authToken: null};
    }

    private get persister() {
        return new InquiryAPIPersister();
    }

    private fetchInquiry(inquiryId: string): Observable<any> {
        this.setState({isInquiryLoading: true});

        return this.persister.fetch(inquiryId, this.credentials).pipe(
            tap(data => {
                this.props.changeCalendar({
                    id: data.calendar.id,
                    name: data.calendar.name,
                    price: {
                        amount: data.calendar.price.amount,
                        currency: {
                            code: data.calendar.price.currency.code
                        }
                     },
                    public: data.calendar.public,
                    account: {
                        id: data.calendar.account.id,
                        address: {
                            addressLine1: data.calendar.account.address.addressLine1,
                            addressLine2: data.calendar.account.address.addressLine2,
                            addressLine3: data.calendar.account.address.addressLine3,
                            postcode: data.calendar.account.address.postcode,
                            city: data.calendar.account.address.city,
                            country: data.calendar.account.address.country
                        },
                        birthdate: data.calendar.account.birthdate,
                        firstName: data.calendar.account.firstName,
                        lastName: data.calendar.account.lastName,
                        invoiceAddress: {
                            addressLine1: data.calendar.account.invoiceAddress.addressLine1,
                            addressLine2: data.calendar.account.invoiceAddress.addressLine2,
                            addressLine3: data.calendar.account.invoiceAddress.addressLine3,
                            postcode: data.calendar.account.invoiceAddress.postcode,
                            city: data.calendar.account.invoiceAddress.city,
                            country: data.calendar.account.invoiceAddress.country,
                            taxId: data.calendar.account.invoiceAddress.taxId
                        },
                        phone: data.calendar.account.phone,
                        residency: data.calendar.account.residency
                    }
                });
                this.props.changeInquiry(data);
                this.props.changeInquiryPaymentId(data.payments[0].id);
                this.inquiryFormUpdater.updateInquiryFormFromAPI(data);
                this.props.changeShowFormLoader(false);
                this.setState({isInquiryLoading: false});
            }),
            catchError(error => {
                this.alertManager.addAlert(`You are not allowed to view this Inquiry, contact your Clinic administrator`);
                this.props.changeShowFormLoader(false);
                this.setState({isInquiryLoading: false});
                return throwError(error);
            }),
            tap(() => {
                return of();
            }),
        );
    };

    private needsToClearSecret(inquiryId: string | null): boolean {
        return !this.props.isClinicHost && inquiryId === null && null !== this.props.authToken && undefined !== this.props.authToken;
    }
}

export default connect(
    (state: RootState) => ({
        secret: secretSelector(state),
        authToken: authTokenSelector(state),
        formSteps: formStepsSelector(state),
        inquiryForm: formSelector(state),
        inquiryId: inquiryIdSelector(state),
        isClinicHost: isClinicHostSelector(state),
        showFormLoader: showFormLoaderSelector(state),
        isFormInitialized: isFormInitializedSelector(state),
    }),
    {
        changeShowFormLoader,
        changeInquiryId,
        changeInquiry,
        setSecret,
        changeCalendar,
        changeInquiryPaymentId
    }
)(withRouter(InquiryForm));
