import {meditripInquiryAPI} from "./provider/meditripInquiry";
import {Observable} from "rxjs";
import { RestQueryParams } from "meditrip-common-web";

export function assignConsultationToUserAPI(
    inquiryId: string,
    accessToken: string,
    secret: string,
): Observable<any> {
    return meditripInquiryAPI.put(
        `online_consultation/${inquiryId}/assign`,
        {},
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`,
            'X-Online-Consultation-Secret': secret,
        },
    );
}
