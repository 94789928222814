import {combineEpics, Epic, ofType, StateObservable} from "redux-observable";
import {catchError, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {PayloadAction} from "redux-starter-kit";
import {addAlert} from "meditrip-common-web";
import {
  changeEventPlaceholder,
  IChangeEventPlaceholder
} from 'store/reducers/metadataSlice';
import {createEventPlaceholderAPI} from "../../api/createEventPlaceholder";

const changeEventPlaceholderEpic: Epic = (action$, state$: StateObservable<any>) =>
  action$.pipe(
    ofType(changeEventPlaceholder.type),
    switchMap((action: PayloadAction<IChangeEventPlaceholder>): any => {
      const payload = (action.payload as any);
      return createEventPlaceholderAPI(
        payload.credentials,
        payload.validFrom,
        payload.validUntil,
        payload.startsAt,
        payload.endsAt,
        payload.subjectName,
        payload.subjectId,
        payload.calendarId
      ).pipe(
        catchError((error: any) => {
          return of(
            addAlert({message: error.response ? error.response['hydra:description'] : 'Error occurred while reserving a time slot'})
          )
        }))
      })
    );


const calendarEventsEpic = combineEpics(
  changeEventPlaceholderEpic
);

export default calendarEventsEpic;
