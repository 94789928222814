import { Observable } from "rxjs";
import { meditripRegistrationAPI } from "./provider/meditripRegistration";
import {RestQueryParams} from "meditrip-common-web";

export function confirmResetPasswordAPI(
  plainPassword: string,
  resetPasswordToken: string
): Observable<any> {
  return meditripRegistrationAPI.post(
      `reset-password`,
      {
          plainPassword: plainPassword,
      },
      new RestQueryParams(),
      {
          'X-Authentication-Token': resetPasswordToken
      }
  );
}
