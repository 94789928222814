import {injectable} from 'inversify';
import {addAlert} from 'meditrip-common-web';
import {fixInjectedProperties} from '../ioc';
import store from '../store';

export enum AlertType {
    INFO = "info",
    WARNING = "warning",
    SUCCESS = "success",
    ERROR = "error",
}

export interface IAlertManagerService {
    handleFormApiError(error?: any, type?: AlertType): void;
    handlePaymentSuccess(): void;
    registrationSuccess(): void;
    paymentProcessing(): void;
    loginSuccess(): void;
    loginFailure(): void;
    logoutSuccess(): void;
    addAlert(message: string, type?: AlertType): void;
}

const AlertMessage = {
    base: 'Something went wrong.',
    paymentSuccess: 'Payment completed. Please wait for a conformation email with consultation details.',
    paymentProcessing: 'Your payment is being processed. Please wait.',
    patient: {

    },
    clinic: {

    },
    axaInsurance: {
        loginFailure: 'You are not authorized to login to meditrip Cover Medical Insurance.'
    }
};

@injectable()
class AlertManagerService implements IAlertManagerService {

    constructor() {
        fixInjectedProperties(this);
    }

    public handleFormApiError(error: any = null, type: AlertType = AlertType.WARNING) {
        let message = error ? error : AlertMessage.base;

        if (error) {
            if (error.response && error.response['hydra:description']) {
                message = error.response['hydra:description'];
            } else if (error['hydra:description']) {
                if (error['hydra:description'] === 'You cant set accepted offer') {
                    message = 'That inquiry has been already purchased.'
                } else {
                    message = error['hydra:description']
                }
            } else if (error.message) {
                if (error.message === 'JWT Token not found') {
                    message = 'Login to see requested inquiry.'
                } else {
                    message = error.message
                }
            }
        }

        this.addAlert(message, type);
    };

    public handlePaymentSuccess() {
        this.addAlert('Payment completed. Please wait for a conformation email with consultation details.', AlertType.INFO);
    };

    public paymentProcessing() {
        this.addAlert('Your payment is being processed. Please wait.', AlertType.INFO);
    };

    public registrationSuccess() {
        this.addAlert(
            'Welcome to meditrip Cover Medical Insurance. We have sent you an invitation email.',
            AlertType.INFO);
    };

    public loginSuccess() {
        this.addAlert('Login success. Welcome!', AlertType.INFO);
    };

    public loginFailure() {
        return this.addAlert('Login failed. You have no access to the Panel. Try again or contact with our Admin.', AlertType.WARNING);
    };

    public logoutSuccess() {
        return this.addAlert('You have been logged out.', AlertType.INFO);
    }

    public addAlert(message: string, type: AlertType = AlertType.INFO, displayFor: number = 5000) {
        const alert = {
            message: message,
            type: type,
            displayFor: displayFor,
        };
        store.dispatch(addAlert(alert));
    }
}

export default AlertManagerService;
