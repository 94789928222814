import {
  FormControlType,
  IFormConfig,
  InputType,
  ValidationRules
} from 'meditrip-common-web';

const outputDataMapper = (data: any) => {
  if (data === 'false') {
    return false;
  }
  if (data === 'true' || data === 'on') {
    return true;
  }
  return data;
};

export const patientFormConfig: typeof IFormConfig = {
  controlType: 'group',
  class: '',
  dataAccessor: (data: any) => data,
  outputDataMapper: (data: any, previousStateSnapshot: any) => {
    Object.assign(previousStateSnapshot, data);

    return previousStateSnapshot;
  },
  controls: [
    {
      controlType: 'group',
      key: 'name',
      class: 'form-row',
      controls: {
        name: {
          controlType: 'control',
          defaultValue: '',
          hostClass: 'col-50',
          formControlType: FormControlType.INPUT,
          validationRules: [
            { name: ValidationRules.IS_REQUIRED },
            { name: ValidationRules.MIN_LENGTH, params: { length: 3 } },
          ],
          placeholder: 'form.consultationForm.patient.formControls.name.placeholder',
          label: "form.consultationForm.patient.formControls.name.label",
          type: InputType.TEXT,
        },
        lastName: {
          controlType: 'control',
          defaultValue: '',
          hostClass: 'col-50',
          formControlType: FormControlType.INPUT,
          validationRules: [
            { name: ValidationRules.IS_REQUIRED },
            { name: ValidationRules.MIN_LENGTH, params: { length: 3 } },
          ],
          placeholder: 'form.consultationForm.patient.formControls.lastName.placeholder',
          label: "form.consultationForm.patient.formControls.lastName.label",
          type: InputType.TEXT,
        }
      }
    },
    {
      controlType: 'group',
      key: 'additional_comments',
      class: 'form-row mb-0',
      controls: {
        additionalComments: {
          controlType: 'control',
          defaultValue: '',
          hostClass: 'col-100',
          formControlType: FormControlType.TEXTAREA,
          validationRules: [
          //  { name: ValidationRules.IS_REQUIRED },
          ],
          placeholder: 'form.consultationForm.patient.formControls.additionalComments.label',
          label: "form.consultationForm.patient.formControls.additionalComments.placeholder",
          isLabelHidden: false,
          type: InputType.TEXT,
        },
      },
    },
  ]
};
