import {IInquiryForm} from "../../../store/reducers/formSlice";
import {createConsultationAPI} from "../../../api/createConsultationAPI";
import {map, mapTo} from "rxjs/operators";
import {Observable} from "rxjs";
import {getOnlineConsultationAPI} from "../../../api/getOnlineConsultation";
import {changeOnlineConsultationAPI, IOnlineConsultationParticipant} from "../../../api/changeOnlineConsultation";
import {UserRole} from "meditrip-common-web";

export class InquiryAPIPersister {

    fetch(inquiryId: string, credentials: any) {
        return getOnlineConsultationAPI(inquiryId, credentials);
    }

    persist(id: string | null,
            form: IInquiryForm,
            credentials: any,
            inquiry: any,
            onlineParticipants: IOnlineConsultationParticipant,
            paymentToken: string | null
    ): Observable<string> {
        let participants: IOnlineConsultationParticipant[] = [];
        participants.push(onlineParticipants);
        const data: [any, any, IOnlineConsultationParticipant[] | [], any, any, any, any, string | null] = [
            credentials,
            form.cid,
            participants,
            form.consultationDate,
            form.additionalComments,
            form.onlineConsultationId,
            form.calendarId,
            paymentToken
        ] as any;

        if (null === id || undefined === id) {
            return createConsultationAPI(...data).pipe(map(response => {
                return response.id
            }));
        }

        let updatedParticipants: IOnlineConsultationParticipant[] = [];
        if (inquiry && inquiry.participants && inquiry.participants.length) {
            let apiParticipants = inquiry.participants;
            let patientExists = apiParticipants.some((participant: {[key: string]: any}) => {
                return participant.account.id === onlineParticipants.accountId;
            });
            apiParticipants.map((participant: {[key: string]: any}) => {
                return updatedParticipants.push({
                    accountId: participant.account.id,
                    timeZone: participant.timeZone
                })
            });
            if (!patientExists) {
                updatedParticipants[updatedParticipants.
                    findIndex((participant: {[key: string]: any}) => participant.role === UserRole.PATIENT)] = onlineParticipants;
            }
        }

        const date = form.consultationDate ? form.consultationDate : '';
        return changeOnlineConsultationAPI(
          id,
          credentials,
          updatedParticipants,
          date,
          form.additionalComments).pipe(mapTo(id));
    }
}
