import {Observable} from "rxjs";
import {meditripTreatmentsAPI} from "./provider/meditripTreatmentTypes";
import {RestQueryParams} from "meditrip-common-web";

export function getTreatmentsAPI(): Observable<any> {
    return meditripTreatmentsAPI.get(
        "treatment_categories",
        new RestQueryParams({page: 1}),
        {
            accept: 'application/ld+json',
            'content-type': 'application/json'
        },
    );
}

export function getTreatmentAPI(treatmentTypeId: string): Observable<any> {
    return meditripTreatmentsAPI.get(
        `treatment-type/${treatmentTypeId}`,
        new RestQueryParams(),
        {
            accept: 'application/ld+json',
            'content-type': 'application/json'
        },
    );
}
