import * as React from "react";
import {Component} from "react";
import styles from "./styles.module.scss";
import {Translation} from "meditrip-common-web";

export interface ISummaryTableItem {
    name: string | null,
    label: string | null,
    data: string | null
}

export interface IQuoteListProps {
    readonly items: ISummaryTableItem[];
}

class SummaryList extends Component<IQuoteListProps, any> {
    render() {
        return <dl className={styles.quoteList}>{this.renderList()}</dl>;
    }

    private renderList() {
        return this.props.items.map((quoteItem: ISummaryTableItem, index: number) => {
            return (
                <div key={index}
                     className={`${styles.quoteListRow} ${quoteItem.name ? styles[quoteItem.name] : ''}`}>
                    <dt className={styles.quoteListItemName}><Translation text={quoteItem.label}/></dt>
                    <dd className={styles.quoteListItemValue}>{this.getData(quoteItem)}</dd>
                </div>
            )
        })
    }

    private getData(item: ISummaryTableItem) {
        if (!item.data) {
            return '-';
        }

        if (item.name === 'totalPrice') {
            return (<span className={styles.quoteListItemValueBox}>{item.data.split(" ")[0]}
            <span className={styles.quoteListItemCurrency}>{item.data.split(" ").reverse()[0]}</span></span>);
        }

        return item.data;
    }
}

export default SummaryList;
