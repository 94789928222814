import {meditripInquiryAPI} from "./provider/meditripInquiry";
import {Observable} from "rxjs";
import {RestQueryParams} from "meditrip-common-web";

export function confirmPayment(
    paymentId: string,
    accessToken: string,
    paymentToken: string | null
): Observable<any> {
    let headers: {[key: string]: any} = {
        Authorization: `Bearer ${accessToken}`
    };

    if (paymentToken) {
        headers['X-Payment-Token'] = paymentToken;
    }

    return meditripInquiryAPI.put(
        `payment/${paymentId}/confirm`,
        {},
        new RestQueryParams(),
        headers
    );
}
