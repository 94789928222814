import {meditripInquiryAPI} from "./provider/meditripInquiry";
import {Observable} from "rxjs";
import {RestQueryParams} from "meditrip-common-web";

export interface IOnlineConsultationParticipant {
  accountId: string,
  timeZone: string | null
}

export function changeOnlineConsultationAPI(
  consultationId: string,
  credentials: {
    authToken: string | undefined,
    secret: string | undefined,
  },
  onlineConsultationParticipants: IOnlineConsultationParticipant[],
  startsAt: string,
  notes: string | null
): Observable<any> {
  const payload: any = {
    onlineConsultationParticipants: onlineConsultationParticipants,
    onlineConsultationId: consultationId,
    startsAt: startsAt,
    notes: notes
  };
  let headers = undefined;
  if (undefined !== credentials.authToken) {
    headers = {
      Authorization: `Bearer ${credentials.authToken}`,
    };
  } else {
    headers = {
      'X-Online-Consultation-Secret': credentials.secret,
    };
  }

  return meditripInquiryAPI.put(
    `online_consultation/${consultationId}/update`,
    payload,
    new RestQueryParams(),
    headers
  );
}
