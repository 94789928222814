import {FormControlType, IFormConfig, InputType, ValidationRules} from 'meditrip-common-web';

const outputDataMapper = (data: any) => {
    if (data === 'false') {
        return false;
    }
    if (data === 'true' || data === 'on') {
        return true;
    }
    return data;
};

const isStripeCardValid = (data: any) => {
    if ((data !== null && data !== undefined) && (data.complete !== null && data.complete !== undefined)) {
        return data.complete;
    }
};

export const paymentFormConfig: typeof IFormConfig = {
    controlType: 'group',
    class: '',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'cardholder_name',
            class: 'form-row',
            controls: {
                cardholderName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 3}},
                    ],
                    placeholder: 'form.consultationForm.payment.formControls.cardholderName.placeholder',
                    label: 'form.consultationForm.payment.formControls.cardholderName.label',
                    type: InputType.TEXT,
                }
            }
        },
        {
            controlType: 'group',
            key: 'stripe_card',
            class: 'form-row',
            controls: {
                stripecard: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.STRIPECARD,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.IS_VALID, params: {callback: isStripeCardValid}},
                    ],
                    placeholder: 'form.consultationForm.payment.formControls.stripecard.placeholder',
                    label: 'form.consultationForm.payment.formControls.stripecard.label',
                    stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
                }
            }
        },
        {
            controlType: 'group',
            key: 'terms_and_conditions',
            class: 'form-row',
            controls: {
                termsAndConditions: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.CHECKBOX,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.IS_TRUE},
                    ],
                    isLabelHidden: true,
                    isCheckboxLabelRaw: true,
                    checkboxLabel: `I accept the <a href="https://meditrip.me/terms-and-conditions/" target="_blank">terms and conditions</a>`,
                    outputDataMapper: outputDataMapper,
                }
            }
        },
    ]
};

