import {
    IAPIHandler,
    RxJsApiHandler,
    MappingAPIHandlerDecorator,
    DefaultParamsAPIHandlerDecorator,
    FilteringAPIHandlerDecorator,
    MemoizingAPIHandlerDecorator
} from 'meditrip-common-web';
import memoizee from 'memoizee';

export enum ApiHandlerType {
    Rest
}

export interface IAPIHandlerOptions<T = any, ParamsType = any> {
    readonly type?: ApiHandlerType; // Rest is default
    readonly url?: string;
    readonly defaultHeaders?: Object;
    readonly mapper?: (data: any) => T;
    readonly filter?: (data?: any, payload?: any, params?: ParamsType) => T;
    readonly isList?: boolean;
    readonly defaultParamsProvider?: (params?: ParamsType) => ParamsType;
    readonly memoize?: memoizee.Options<any>;
}

type APIHandlerType = typeof IAPIHandler;

export default function createAPIHandler(
    options: IAPIHandlerOptions
): APIHandlerType {
    let handler: APIHandlerType;

    handler = new RxJsApiHandler(options.url, options.defaultHeaders);

    if (options.mapper) {
        if (typeof options.isList !== "boolean") {
            throw new Error(
                `Option "isList" is required when defining an API handler with a "mapper" option.`
            );
        }
        handler = new MappingAPIHandlerDecorator(
            handler,
            options.mapper,
            options.isList as boolean
        );
    }
    if (options.filter) {
        handler = new FilteringAPIHandlerDecorator(handler, options.filter);
    }
    if (options.defaultParamsProvider) {
        handler = new DefaultParamsAPIHandlerDecorator(
            handler,
            options.defaultParamsProvider
        );
    }
    if (options.memoize) {
        handler = new MemoizingAPIHandlerDecorator(handler, options.memoize);
    }
    return handler;
}
