import React from "react";
import InfoPanel from "../InfoPanel";
import styles from "./styles.module.scss";
import {connect} from "react-redux";
import {AuthUserMenu, isAuthenticatedSelector, Toast} from 'meditrip-common-web';
import {authTokenSelector, paymentTokenSelector} from "../../store/selectors/authSelectors";
import {setSecret} from "store/reducers/authSlice";
import {InquiryFormStep} from '../InquiryForm/inquiryFormStep';
import {changeFormSteps, changeIsClinicHost} from "../../store/reducers/formSlice";
import {RouteComponentProps, Route, Switch, Redirect, withRouter} from "react-router-dom";
import {stepsNotAuthenticated} from "./formSteps"
import {formStepsSelector, inquirySelector, showFormLoaderSelector} from '../../store/selectors/formSelectors';
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {IAuthenticationFlowService} from "../../service/authenticationFlowService";
import InquiryForm from "../InquiryForm";

interface IFormHostProps extends RouteComponentProps {
    readonly authToken: string | null;
    readonly formSteps: InquiryFormStep[];
    readonly showFormLoader: boolean;
    readonly isAuthenticated: boolean;
    readonly changeFormSteps: typeof changeFormSteps;
    readonly changeIsClinicHost: typeof changeIsClinicHost;
    readonly setSecret: typeof setSecret;
    readonly paymentToken: string | null;
    readonly inquiry: {[key: string]: any};
}

interface IFormHostState {
}

class FormHost extends React.Component<IFormHostProps, IFormHostState> {
    @lazyInject("AuthenticationFlowService")

    private authenticationFlowService: IAuthenticationFlowService;

    constructor(props: any) {
        super(props);
        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.props.changeIsClinicHost(false);
        let steps = Array.from(stepsNotAuthenticated);
        if (this.props.paymentToken !== null) {
            steps = steps.filter((step: string) => step !== InquiryFormStep.PAYMENT);
        }
        this.props.changeFormSteps(steps);
        const inquirySecret = new URLSearchParams(this.props.location.search).get('s');
        if (inquirySecret) {
            this.props.setSecret(inquirySecret);
        }
    }

    componentDidUpdate(prevProps: Readonly<IFormHostProps>, prevState: Readonly<IFormHostState>, snapshot?: any): void {
        if (this.props.inquiry !== prevProps.inquiry && this.props.inquiry) {

            if (this.props.inquiry.paidAt) {
                let steps = Array.from(stepsNotAuthenticated);
                steps = steps.filter((step: string) => step !== InquiryFormStep.PAYMENT);
                this.props.changeFormSteps(steps);
            }


        }
    }

    render() {
        if (!this.props.formSteps.length) {
            return null;
        }
        return (
            <section className={styles.consultationHostSection}>
                <InfoPanel/>
                <div className={styles.consultationForm}>
                    <div className={styles.header}>
                        <AuthUserMenu styles={styles}
                                      authenticationFlow={this.authenticationFlowService}
                                      isLoading={this.props.showFormLoader}
                                      envData={process.env}/>
                    </div>
                    <Switch>
                      <Route path={`/inquiry/:step/:inquiryId`} component={InquiryForm} isClinicHost={false}/>
                      <Route path={`/inquiry/:step`} component={InquiryForm} isClinicHost={false}/>
                      <Route component={() => (<Redirect to={`/inquiry/${InquiryFormStep.TREATMENT}`}/>)}/>
                    </Switch>
                </div>
                <Toast/>
            </section>
        );
    }
}

export default connect(
    (state: any) => ({
        authToken: authTokenSelector(state),
        formSteps: formStepsSelector(state),
        showFormLoader: showFormLoaderSelector(state),
        isAuthenticated: isAuthenticatedSelector(state),
        paymentToken: paymentTokenSelector(state),
        inquiry: inquirySelector(state),
    }),
    {
        setSecret,
        changeFormSteps,
        changeIsClinicHost,
    }
)(withRouter(FormHost));
