import {combineEpics, Epic, ofType, StateObservable} from "redux-observable";
import {catchError, map, mergeMap, switchMap} from "rxjs/operators";
import {RootState} from "../reducers";
import {
    changeIsAuthenticated,
    confirmResetPasswordStart,
    getLoginCredentials,
    IConfirmResetPasswordStart,
    IGetLoginCredentials,
    IRegisterStart,
    IResetPasswordStart,
    registerStart,
    resetPasswordStart,
    setAuthStateFailure,
    setAuthTokens
} from "../reducers/authSlice";
import {of} from "rxjs";
import {PayloadAction} from "redux-starter-kit";
import {loginAPI} from "../../api/loginAPI";
import {resetPasswordAPI} from "../../api/resetPasswordAPI";
import {registerClinicAPI} from "../../api/registerClinicAPI";
import {addAlert} from "meditrip-common-web";
import {confirmResetPasswordAPI} from "../../api/confirmResetPassword";
import store from '../../store';

const loginEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(getLoginCredentials.type),
        switchMap((action: PayloadAction<IGetLoginCredentials>): any => {
            return loginAPI(action.payload.username, action.payload.password)
                .pipe(
                    mergeMap((resp: any) => {
                        store.dispatch(addAlert({message: 'Welcome to meditrip Cover.'}));
                        const actions: any[] = [];
                        actions.push(setAuthTokens(resp.authToken, resp.refreshToken));
                        actions.push(changeIsAuthenticated(true));
                        return actions;
                    })
                )
        }),
        catchError((error: any) => of(setAuthStateFailure(error.toString())))
    );


const resetPasswordEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(resetPasswordStart.type),
        switchMap((action: PayloadAction<IResetPasswordStart>): any => {
            return resetPasswordAPI(action.payload.email, action.payload.redirectUrl)
                .pipe(
                    map((resp: any) => {
                        return addAlert({
                            message: 'An email was sent to an indicated email address. Proceed with the instructions to change the password.',
                        })
                    })
                )
        }),
        catchError((error: any) => of(setAuthStateFailure(error.toString())))
    );

const confirmResetPasswordEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(confirmResetPasswordStart.type),
        switchMap((action: PayloadAction<IConfirmResetPasswordStart>): any => {
            return confirmResetPasswordAPI(action.payload.password, action.payload.confirmationToken)
                .pipe(
                    map(() => {
                        window.location.href = window.location.origin + '/auth/login';
                        return addAlert({message: 'Password has been changed.'})
                    })
                )
        }),
        catchError((error: any) => of(setAuthStateFailure(error.toString())))
    );

const registerClinicEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(registerStart.type),
        switchMap((action: PayloadAction<IRegisterStart>): any => {
            return registerClinicAPI(action.payload.email,
                action.payload.password,
                action.payload.firstName,
                action.payload.lastName,
                action.payload.birthdate,
                action.payload.addressLine1,
                action.payload.addressLine2,
                action.payload.addressLine3,
                action.payload.postcode,
                action.payload.city,
                action.payload.country,
                action.payload.residency,
                action.payload.reference
            ).pipe(
                map(() => {
                    return addAlert({
                        message: 'A confirmation email was sent to indicated email address. ' +
                            'Please proceed with the instructions to complete the registration.',
                    })
                })
            )
        }),
        catchError((error: any) => of(setAuthStateFailure(error.toString())))
    );

const authEpic = combineEpics(
    loginEpic,
    resetPasswordEpic,
    confirmResetPasswordEpic,
    registerClinicEpic
);

export default authEpic;
